<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="450px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-width="80px" ref="dataForm">
            <el-form-item label="姓名" prop="id">
                <el-input size="small" v-model="username" placeholder="姓名" :disabled="true"></el-input>
            </el-form-item>
			<el-form-item label="成绩" prop="userScore">
                <el-input size="small" v-model="dataForm.userScore" placeholder="成绩" maxlength="3"></el-input>
            </el-form-item>
			<el-form-item label="状态" prop="passFlag">
				<el-select size="small" v-model="dataForm.passFlag" filterable>
					<el-option value="1" label="未开始" :disabled="true"></el-option>
					<el-option value="3" label="通过"></el-option>
					<el-option value="2" label="未通过"></el-option>
				</el-select>
            </el-form-item>
        </el-form>
		<span slot="footer" class="dialog-footer">
			<el-button class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			visible: false,
			title: '修改成绩',
			username: '',
			dataForm: {
				id: '',
				userScore: '',
				passFlag: ''
			},
			dataRule: {
				id: [
					{required: true,message: '姓名不能为空', trigger: "blur"}
				],
				userScore: [
					{required: true,message: '成绩不能为空', trigger: "blur"},
					{pattern: /^[0-9]\d*$/, message: '请输入大于0的正整数'}
				],
				passFlag: [
					{required: true,message: '请选择考试状态', trigger: "change"}
				]
			}
		}
	},
	methods: {
		init(data) {
			this.visible = true;
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				this.dataForm.id = data.id;
				this.dataForm.userScore = data.userScore;
				this.dataForm.passFlag = data.passFlag+'';
				this.username = data.userName;
			})

		},
		// 提交
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let that = this;
					that.$request.post(
						'updateOfflineResult',
						true,
						that.dataForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('getDataList');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 18px;
	.el-select,
	/deep/ .el-range-editor.el-input__inner,
	/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
		width: 100%;
	}
	/deep/ .el-input__inner{
		height: 32px;
		line-height: 32px;
	}
}
.submit-btn{
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	// padding: 0 !important;
	// line-height: 24px;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 20px 20px 40px !important;
}
</style>
